<template>
  <div class="container">
    <h1 class="headline">{{ component.title }}</h1>
    <div class="counter-container">
      <strapi-countdown-counter :time="countdown.days" unit="Days" />
      <strapi-countdown-counter :time="countdown.hours" unit="Hours" />
      <strapi-countdown-counter :time="countdown.minutes" unit="Minutes" />
      <strapi-countdown-counter :time="countdown.seconds" unit="Seconds" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiCountdownComponent } from '~/apollo/types/types';

const props = defineProps<{
  component: StrapiCountdownComponent;
}>();

const countdown = reactive({
  days: null,
  hours: null,
  minutes: null,
  seconds: null,
});

const getCountdown = () => {
  const dateDiffUnix =
    new Date(props.component.endDate).getTime() - new Date().getTime();
  const remainingSeconds = Math.floor(dateDiffUnix / 1000);

  return {
    days: Math.floor(remainingSeconds / (60 * 60 * 24)),
    hours: Math.floor((remainingSeconds / (60 * 60)) % 24),
    minutes: Math.floor((remainingSeconds / 60) % 60),
    seconds: remainingSeconds % 60,
  };
};

const updateCounter = () => {
  const remaining = getCountdown();
  countdown.days = remaining.days;
  countdown.hours = remaining.hours;
  countdown.minutes = remaining.minutes;
  countdown.seconds = remaining.seconds;
};

const { resume, pause } = useRafFn(
  () => {
    updateCounter();
  },
  {
    immediate: false,
  },
);

onMounted(() => {
  resume();
});

onUnmounted(() => {
  pause();
});
</script>
<style scoped lang="scss">
.container {
  text-align: center;
}

.headline {
  font-size: 24px;
  margin-bottom: 20px;
}

.counter-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-weight: bold;
}
</style>
